// import React, { useEffect, useState } from 'react';

// function InstallButton() {
//   const [deferredPrompt, setDeferredPrompt] = useState(null);

//   useEffect(() => {
//     const handleBeforeInstallPrompt = (e) => {
//       e.preventDefault();
//       console.log(e);
//       setDeferredPrompt(e);
//     };

//     window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

//     return () => {
//       window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
//     };
//   }, []);

//   const handleInstallClick = async () => {
//     if (deferredPrompt) {
//       deferredPrompt.prompt();
//       const { outcome } = await deferredPrompt.userChoice;
//       if (outcome === 'accepted') {
//         setDeferredPrompt(null);
//       } else if (outcome === 'dismissed') {
//         console.log('User dismissed the install prompt');
//       }
//     }
//   };

//   return (
//     <button id="installApp" onClick={handleInstallClick}>
//       Install
//     </button>
//   );
// }

// export default InstallButton;


import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("beforeinstallprompt event triggered");
      setSupportsPWA(true);
      setPromptInstall(e);
    };

    const isInstalled = localStorage.getItem("isPWAInstalled");
    if (!isInstalled) {
      window.addEventListener("beforeinstallprompt", handler);
    }

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const onClick = (evt) => {
    console.log(evt);
    console.log("abc");
    evt.preventDefault();
    if (promptInstall) {
      promptInstall.prompt();
    }
  };

  const handleAppInstalled = () => {
    console.log("App installed");
    localStorage.setItem("isPWAInstalled", "true");
  };

  useEffect(() => {
    window.addEventListener("appinstalled", handleAppInstalled);
    return () => window.removeEventListener("appinstalled", handleAppInstalled);
  }, []);

  const notify = () =>
    toast(
      <div className="toast-container"> 
        <span>Install Counter App</span>
        <button onClick={onClick}>Install</button>
      </div>,{
        onClick: onClick,
        autoClose: false,
        position: "top-center",
      }
    );

  useEffect(() => {
    // Show the popup after a delay (e.g., 10 seconds)
    const timer = setTimeout(() => {
      notify();
    }, 10000); // Adjust the time delay as needed (e.g., 10000 for 10 seconds)


    return () => clearTimeout(timer); // Clear the timeout to avoid memory leaks
  }, []);

  if (!supportsPWA) {
    return null;
  }


  return (
  <>
   <button onClick={onClick}>Install App!</button>
        <ToastContainer hideProgressBar={true} autoClose={10000}/></>
  );
};

export default InstallPWA;
