import React, { useState } from "react";

function  Counter(props) {
  let [count, setCount] = useState(0);

  // This variable will save the event for later use.
  // let deferredPrompt;
  // window.addEventListener("beforeinstallprompt", (e) => {
  //   // Prevents the default mini-infobar or install dialog from appearing on mobile
  //   e.preventDefault();
  //   // Save the event because you'll need to trigger it later.
  //   deferredPrompt = e;
  //   // Show your customized install prompt for your PWA
  //   // Your own UI doesn't have to be a single element, you
  //   // can have buttons in different locations, or wait to prompt
  //   // as part of a critical journey.
  //   console.log("abc");
  //   showInAppInstallPromotion();
  // });

  function increment() {
    setCount(count + 1);
  }

  function decrement() {
    setCount(count - 1);
  }

  return (
    <div style={{ textAlign: "center" }}>
      <h3>Counter App 2</h3>
      <div>
        <button variant="contained" onClick={increment}>
          +
        </button>
        <span> {count} </span>
        <button variant="contained" onClick={decrement}>
          -
        </button>
      </div>
    </div>
  );
}

export default Counter;
